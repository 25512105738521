var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card floating help"},[_c('div',{staticClass:"card-title"},[_c('h2',[_vm._v(_vm._s(_vm.$t("help.help")))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"shortcuts-container"},[_c('div',{staticClass:"shortcut-container"},[_vm._m(0),_c('div',[_vm._v(_vm._s(_vm.$t("help.f1")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(1),_c('div',[_vm._v(_vm._s(_vm.$t("help.f2")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(2),_c('div',[_vm._v(_vm._s(_vm.$t("help.del")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(3),_c('div',[_vm._v(_vm._s(_vm.$t("help.esc")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(4),_c('div',[_vm._v(_vm._s(_vm.$t("help.ctrl.s")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(5),_c('div',[_vm._v(_vm._s(_vm.$t("help.ctrl.f")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(6),_c('div',[_vm._v(_vm._s(_vm.$t("help.ctrl.click")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(7),_c('div',[_vm._v(_vm._s(_vm.$t("help.click")))])]),_c('div',{staticClass:"shortcut-container"},[_vm._m(8),_c('div',[_vm._v(_vm._s(_vm.$t("help.doubleClick")))])])])]),_c('div',{staticClass:"card-action"},[_c('button',{staticClass:"button button--flat",attrs:{"type":"submit","aria-label":_vm.$t('buttons.ok'),"title":_vm.$t('buttons.ok')},on:{"click":function($event){return _vm.$store.commit('closeHovers')}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.ok"))+" ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("F1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("F2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("DEL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("ESC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("CTRL + S")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("CTRL + F")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("CTRL + Click")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("Click")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut"},[_c('strong',[_vm._v("Double click")])])
}]

export { render, staticRenderFns }