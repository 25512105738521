<template>
  <div class="card floating help">
    <div class="card-title">
      <h2>{{ $t("help.help") }}</h2>
    </div>

    <div class="card-content">
      <div class="shortcuts-container">
        <div class="shortcut-container">
          <div class="shortcut"><strong>F1</strong></div>
          <div>{{ $t("help.f1") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>F2</strong></div>
          <div>{{ $t("help.f2") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>DEL</strong></div>
          <div>{{ $t("help.del") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>ESC</strong></div>
          <div>{{ $t("help.esc") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>CTRL + S</strong></div>
          <div>{{ $t("help.ctrl.s") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>CTRL + F</strong></div>
          <div>{{ $t("help.ctrl.f") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>CTRL + Click</strong></div>
          <div>{{ $t("help.ctrl.click") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>Click</strong></div>
          <div>{{ $t("help.click") }}</div>
        </div>
        <div class="shortcut-container">
          <div class="shortcut"><strong>Double click</strong></div>
          <div>{{ $t("help.doubleClick") }}</div>
        </div>
      </div>
    </div>
    <div class="card-action">
      <button type="submit" @click="$store.commit('closeHovers')" class="button button--flat"
        :aria-label="$t('buttons.ok')" :title="$t('buttons.ok')">
        {{ $t("buttons.ok") }}
      </button>
    </div>
  </div>
</template>

<script>
export default { name: "help" };
</script>
